<template>
  <div class="datacenter__app-menu-main">
    <div class="datacenter__app-menu-headline">{{ $t('choose_app') }}</div>
    <div class="datacenter__app-menu-items">
      <div
        class="datacenter__app-menu-button"
        :class="{'datacenter__app-menu-disabled': !isAppAvailable('IBS_DATACENTER')}"
      >
        <div class="max-w-xs ">
          <router-link :to="{ name: 'datacenter' }" >
            <IbsDatacenterLogo class="datacenter__app-menu-shadow"/>
          </router-link>
        </div>
        <div class="mt-3 text-xl">{{ $t('ibs_dc_app_name') }}</div>
        <div>{{ $t('ibs_dc_short_description') }}</div>
        <div>{{ $t('ibs_dc_slogan') }}</div>
      </div>
      <div
        class="datacenter__app-menu-button"
        :class="{'datacenter__app-menu-disabled': !isAppAvailable('IBS_ABO')}"
      >
        <div class="max-w-xs" >
          <router-link  :to="{ name: 'contracts' }">
            <IbsAboLogo class="datacenter__app-menu-shadow"/>
          </router-link>
        </div>
        <div class="mt-3 text-xl">{{ $t('ibs_abo_app_name') }}</div>
        <div>{{ $t('ibs_abo_short_description') }}</div>
        <div>{{ $t('ibs_abo_slogan') }}</div>
      </div>
    </div>
  </div>

</template>

<script>

import IbsAboLogo from '../components/IbsAboLogo.vue';
import IbsDatacenterLogo from '../components/IbsDatacenterLogo.vue';

export default {
  components: { IbsAboLogo, IbsDatacenterLogo },

  created() {
    this.getConfigs();
  },
  computed: {
    appRights() {
      return this.$store.state.authentication.appRights;
    },
  },
  methods: {
    isAppAvailable(app) {
      return this.appRights.some((e) => e === app);
    },
    getConfigs() {
      this.$store.dispatch('getConfigs')
        .then((config) => {
          this.$t('countries')
            .forEach((country) => {
              if (country[0] === config.countryId) {
                this.$i18n.locale = country[2].label.toLowerCase();
                if (this.$i18n._vm.numberFormats[country[2].label.toLowerCase()]) {
                  this.$i18n._vm.numberFormats[country[2].label.toLowerCase()].currency.currency = config.currencyCode;
                }
              }
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.datacenter__app-menu-headline {
  @apply mt-4 mb-10 text-xl;
  @screen md {
    @apply text-3xl;
  }
  @screen xl {
    @apply mb-14 text-4xl;
  }
}

.datacenter__app-menu-main {
  @apply flex flex-col items-center justify-center flex-grow;
  @apply pt-5 text-gray-800 min-h-screen-action-bar;
}

.datacenter__app-menu-items {
  @apply flex flex-row flex-wrap items-center justify-center;

}
.datacenter__app-menu-button {
  @apply flex flex-col;
  @apply mb-6 mx-4;
  @screen xl {
    @apply mx-12;
  }
}

.datacenter__app-menu-shadow {
  @apply transition duration-200 shadow-sm;
  &:hover {
    @apply shadow-lg;
  }
}
.datacenter__app-menu-disabled {
    opacity: 0.5;
    pointer-events: none;
}

</style>
